import React, { FC } from 'react';
import { DefaultTemplate } from 'templates';
import { Cell, Grid, SectionContainer } from 'components/Grid';
import { Heading } from 'components/Layout';

import styles from 'scss/landing-page.module.scss';
import gridStyles from 'scss/grid.module.scss';
import { ContactButton } from 'components/Buttons';
import { Link } from 'components/helpers';

const InterviewPreparation: FC = () => {
  return (
    <DefaultTemplate
      pageTitle='Software Engineering Interview Preparation'
      metaDescription='Prepare for your software engineering interview with our training courses. We offer courses in UI dev, Data Structures and Algorithms, System Design, and Soft Skills and Leadership. Each course is tailored to specific aspects of interviews with live mock interviews.'
    >
      <div className={styles.pageMain}>
        <SectionContainer name='interview-preparation'>
          <Grid margin={'-15px'} columns={12}>
            <Cell padding={15} width={{ xs: 12 }}>
              <Heading
                tagLevel={1}
                styleLevel={1}
                classes={gridStyles.marginTop0}
              >
                <strong>Software Engineering Interview Preparation</strong>
              </Heading>
              <Heading
                tagLevel={1}
                styleLevel={2}
                classes={gridStyles.marginTop0}
              >
                Overview
              </Heading>
              <p>
                Are you preparing for a software engineering interview? Our
                training courses are designed to help you develop the skills you
                need to excel in your next interview. We offer courses with live
                mock interviews in UI development, Data Structures and
                Algorithms, System Design, Soft Skills and Leadership, and
                Salary Negotiation each tailored to specific aspects of software
                engineering interviews.
              </p>
              <Heading
                tagLevel={1}
                styleLevel={2}
                classes={gridStyles.marginTop0}
              >
                Courses
              </Heading>
              <ul className={gridStyles.marginBottom2x}>
                <li>
                  <Link to='/interview-preparation/ui-coding-challenge'>
                    UI Coding Challenge
                  </Link>
                  <ul>
                    <li>
                      Two 1.5 hour sessions focused on the UI coding challenge
                      portion of software engineering interviews
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to='/interview-preparation/data-structure-and-algorithms-coding-challenge'>
                    Data Structures and Algorithms Coding Challenge
                  </Link>
                  <ul>
                    <li>
                      Two 1.5 hour sessions focused on the Data Structures and
                      Algorithms portion of software engineering interviews
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to='/interview-preparation/system-design-interview'>
                    System Design Interview
                  </Link>
                  <ul>
                    <li>
                      Two 1.5 hour sessions to prepare you for the system design
                      portion of software engineering interviews
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to='/interview-preparation/soft-skills-and-leadership-interview'>
                    Soft Skills and Leadership Interview
                  </Link>
                  <ul>
                    <li>
                      A 1 hour session to prepare you for displaying soft skills
                      and leadership necessary in software engineering
                      interviews
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to='/interview-preparation/salary-negotiation'>
                    Salary Negotiation
                  </Link>
                  <ul>
                    <li>
                      A 1 hour crash course session to help you secure a
                      competitive salary and propel your career forward
                    </li>
                  </ul>
                </li>
              </ul>
              <div className={gridStyles.textCenter}>
                <ContactButton>Email to schedule</ContactButton>
              </div>
            </Cell>
          </Grid>
        </SectionContainer>
      </div>
    </DefaultTemplate>
  );
};

export default InterviewPreparation;
